export const BRAND_LIST = [
  { slug: "Lexpure", name: "Lexpure" },
  { slug: "Cruze", name: "Cruze" },
];
export const SCANNING_REPORT_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const SCANNING_REMINDER_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const PRODUCT_TRANSFER_REMINDER_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const PRODUCT_CLAIM_REMINDER_INTERVAL = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const OTHER_PRODUCT_SCAN = [
  { slug: "daily", name: "Daily" },
  { slug: "weekly", name: "Weekly" },
  { slug: "half_month", name: "Half Month" },
  { slug: "monthly", name: "Monthly" },
];
export const ROLE = [
  { slug: "dealer", name: "Dealer" },
  { slug: "retailer", name: "Sub-Dealer" },
  { slug: "technician", name: "Technician" },
];

export const ACHIEVERS_SHOW_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const OUTLET_DISPLAY_SETTING = [
  { slug: "yes", name: "On" },
  { slug: "no", name: "Off" },
];
export const ACHIEVERS_SHOW = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const SCAN_ALLOW_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const SCAN_ALLOW = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const SPECIAL_ONLY_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const SPECIAL_ONLY = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const ACHIEVEMENT_FILTER = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const STATUS = [
  { slug: "1", name: "1" },
  { slug: "0", name: "0" },
];
export const REWARD_STATUS = [
  { slug: "yes", name: "Active" },
  { slug: "no", name: "Deactive" },
];
export const REWARD_ALLOW_SCAN = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const BONUS_POINT_ACHIEVED_STATUS = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const SEMINAR_STATUS = [
  { slug: "yes", name: "Active" },
  { slug: "no", name: "Deactive" },
];
export const ATTEND_SEMINAR = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const IS_SENT = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const INTERESTED = [
  { slug: "yes", name: "Yes" },
  { slug: "no", name: "No" },
];
export const ACHIEVEMENT = [
  { slug: "1", name: "Yes" },
  { slug: "0", name: "No" },
];
export const WHATSAPP_MESSAGE_TYPE = [
  { slug: "scanning_report", name: "Scanning Report" },
  { slug: "scan_reminder", name: "Scan Reminder" },
  { slug: "product_transfer_reminder", name: "Product Transfer Reminder" },
  { slug: "product_claim", name: "Product Claim" },
  { slug: "product_transfer_sender", name: "Product Transfer (Sender)" },
  { slug: "product_transfer_receiver", name: "Product Transfer (Receiver)" },
  { slug: "other_product_scan", name: "Other Product Scan" },
  { slug: "birthday_anniversary", name: "Birthday Anniversary" },
  { slug: "reward_gift", name: "Reward Gift" },
];
export const getWhatsappMessageTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = WHATSAPP_MESSAGE_TYPE.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const DELIVERY_STATUS = [
  { slug: "Delivered", name: "Delivered" },
  { slug: "Failed", name: "Failed" },
  { slug: "Pending", name: "Pending" },
  { slug: "Read", name: "Read" },
  { slug: "Sent", name: "Sent" },
];
export const ROLE_DEALER_SLUG = "dealer";
export const ROLE_SUB_DEALER_SLUG = "retailer";
export const ROLE_TECHNICIAN_SLUG = "technician";
export const GENERAL_TICKET_SLUG = "general";
export const TICKET_TYPE_LIST = (t) => {
  return [
    { slug: ROLE_DEALER_SLUG, name: t("dealer") },
    { slug: ROLE_SUB_DEALER_SLUG, name: t("sub_dealer") },
    { slug: ROLE_TECHNICIAN_SLUG, name: t("technician") },
    { slug: GENERAL_TICKET_SLUG, name: t("general") },
  ];
};
export const getTicketTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = TICKET_TYPE_LIST(t).filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const ROLE_LIST = (t) => {
  return [
    { slug: ROLE_DEALER_SLUG, name: t("dealer") },
    { slug: ROLE_SUB_DEALER_SLUG, name: t("sub_dealer") },
    { slug: ROLE_TECHNICIAN_SLUG, name: t("technician") },
  ];
};
export const getRoleObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = ROLE_LIST.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const SCAN_REWARD_TYPE_GIFT_SLUG = "gift";
export const SCAN_REWARD_TYPE_BONUS_POINT_SLUG = "bonus_point";
export const SCAN_REWARD_TYPE_LIST = (t) => {
  return [
    { slug: "gift", name: t("gift") },
    { slug: "bonus_point", name: t("bonus_point") },
  ];
};
export const getScanRewardTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = SCAN_REWARD_TYPE_LIST(t).filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
export const SCAN_TYPE_LIST = (t) => {
  return [
    { slug: "box", name: t("box") },
    { slug: "product", name: t("product") },
  ];
};
export const SCAN_CODE_REPORT_TABLE_TYPE_OLD_SLUG = "old";
export const SCAN_CODE_REPORT_TABLE_TYPE_NEW_SLUG = "new";
export const SCAN_CODE_REPORT_TABLE_TYPE = [
  { slug: SCAN_CODE_REPORT_TABLE_TYPE_OLD_SLUG, name: "Old" },
  { slug: SCAN_CODE_REPORT_TABLE_TYPE_NEW_SLUG, name: "New" },
];
export const getScanCodeReportTableTypeObjBySlug = (t, slug) => {
  let selectedSlug = null;
  const filteredData = SCAN_CODE_REPORT_TABLE_TYPE.filter(function (item) {
    return item.slug == slug;
  });
  if (filteredData.length > 0) {
    selectedSlug = filteredData[0];
  }
  return selectedSlug;
};
// ticket
export const TICKET_STATUS_LIST = (t) => {
  return [
    { value: "open", label: t("Open") },
    { value: "closed", label: t("Close") },
    { value: "in_progress", label: t("In Progress") },
  ];
};
export const getTicketStatusObjByValue = (t, value) => {
  let selectedValue = null;
  const filteredData = TICKET_STATUS_LIST(t).filter(function (item) {
    return item.value == value;
  });
  if (filteredData.length > 0) {
    selectedValue = filteredData[0];
  }
  return selectedValue;
};

//Priority
export const PRIORITY_STATUS_LIST = (t) => {
  return [
    { value: "low", label: t("Low") },
    { value: "medium", label: t("Medium") },
    { value: "high", label: t("High") },
  ];
};
export const getPriorityObjByValue = (t, value) => {
  let selectedValue = null;
  const filteredData = PRIORITY_STATUS_LIST(t).filter(function (item) {
    return item.value == value;
  });
  if (filteredData.length > 0) {
    selectedValue = filteredData[0];
  }
  return selectedValue;
};
