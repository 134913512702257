import { getUserPermissionList } from "./StorageHelper";

export const permissionSlugs = {
  staff: "staff",
  staff_role: "staff.role",
  staff_role_list: "staff.role.list",
  staff_role_create: "staff.role.create",
  staff_role_edit: "staff.role.edit",
  staff_role_export: "staff.role.export",
  staff_list: "staff.list",
  staff_create: "staff.create",
  staff_edit: "staff.edit",
  staff_delete: "staff.delete",
  staff_export: "staff.export",
  dealer: "dealer",
  dealer_report: "dealer.report",
  dealer_report_list: "dealer.report.list",
  dealer_report_edit: "dealer.report.edit",
  dealer_report_lock_unlock: "dealer.report.lock.unlock",
  dealer_report_change_role: "dealer.report.change.role",
  dealer_report_delete: "dealer.report.delete",
  dealer_report_export: "dealer.report.export",
  dealer_reward_point: "dealer.reward.point",
  dealer_reward_point_export: "dealer.reward.point.export",
  dealer_reward_summary: "dealer.reward.summary",
  dealer_reward_summary_export: "dealer.reward.summary.export",
  dealer_ticket: "ticket.dealer",
  verify_dealer_account: "verify.dealer.account",
  verify_dealer_account_list: "verify.dealer.account.list",
  verify_dealer_account_accept_reject: "verify.dealer.account.accept.reject",
  verify_dealer_account_export: "verify.dealer.account.export",
  sub_dealer: "sub.dealer",
  sub_dealer_report: "sub.dealer.report",
  sub_dealer_report_list: "sub.dealer.report.list",
  sub_dealer_report_edit: "sub.dealer.report.edit",
  sub_dealer_report_lock_unlock: "sub.dealer.report.lock.unlock",
  sub_dealer_report_change_role: "sub.dealer.report.change.role",
  sub_dealer_report_delete: "sub.dealer.report.delete",
  sub_dealer_report_export: "sub.dealer.report.export",
  sub_dealer_reward_point: "sub.dealer.reward.point",
  sub_dealer_reward_point_export: "sub.dealer.reward.point.export",
  sub_dealer_reward_summary: "sub.dealer.reward.summary",
  sub_dealer_reward_summary_export: "sub.dealer.reward.summary.export",
  sub_dealer_ticket: "ticket.sub.dealer",
  verify_sub_dealer_account: "verify.sub.dealer.account",
  verify_sub_dealer_account_list: "verify.sub.dealer.account.list",
  verify_sub_dealer_account_accept_reject:
    "verify.sub.dealer.account.accept.reject",
  verify_sub_dealer_account_export: "verify.sub.dealer.account.export",
  technician: "technician",
  technician_report: "technician.report",
  technician_report_list: "technician.report.list",
  technician_report_edit: "technician.report.edit",
  technician_report_lock_unlock: "technician.report.lock.unlock",
  technician_report_change_role: "technician.report.change.role",
  technician_report_delete: "technician.report.delete",
  technician_report_export: "technician.report.export",
  technician_reward_point: "technician.reward.point",
  technician_reward_point_export: "technician.reward.point.export",
  technician_reward_summary: "technician.reward.summary",
  technician_reward_summary_export: "technician.reward.summary.export",
  technician_ticket: "ticket.technician",
  report: "report",
  otp_report: "otp.report",
  otp_report_list: "otp.report.list",
  scan_code_report: "scan.code.report",
  scan_code_report_list: "scan.code.report.list",
  scan_code_report_export: "scan.code.report.export",
  whatsapp_message_report: "whatsapp.message.report",
  whatsapp_message_report_list: "whatsapp.message.report.list",
  whatsapp_message_report_export: "whatsapp.message.report.export",
  check_user_detail_report: "check.user.detail.report",
  check_user_detail_report_list: "check.user.detail.report.list",
  monthwise_scan_percentage_report: "month.wise.scan.percentage.report",
  bonus_point: "bonus.point",
  bonus_point_achiever: "bonus.point.achiever",
  bonus_point_achiever_list: "bonus.point.achiever.list",
  bonus_point_achiever_export: "bonus.point.achiever.export",
  overall_reward_achieved: "overall.reward.achieved",
  overall_reward_achieved_list: "overall.reward.achieved.list",
  overall_reward_achieved_export: "overall.reward.achieved.export",
  overall_point_achieved: "overall.point.achieved",
  overall_point_achieved_list: "overall.point.achieved.list",
  overall_point_achieved_export: "overall.point.achieved.export",
  overall_reward_redeem: "overall.reward.redeem",
  overall_reward_redeem_list: "overall.reward.redeem.list",
  overall_reward_redeem_export: "overall.reward.redeem.export",
  // user detail
  category: "category",
  category_report: "category.report",
  category_report_list: "category.report.list",
  create_category_report: "create.category.report",
  edit_category_report: "edit.category.report",
  export_category_report: "export.category.report",
  achievement_order: "achievement.order",
  manage_achievement_order: "manage.achievement.order",
  catalog: "catalog",
  catalog_report: "catalog.report",
  catalog_report_list: "catalog.report.list",
  create_catalog_report: "create.catalog.report",
  edit_catalog_report: "edit.catalog.report",
  delete_catalog_report: "delete.catalog.report",
  export_catalog_report: "export.catalog.report",
  catalog_order: "catalog.order",
  manage_catalog_order: "manage.catalog.order",
  outlet: "outlet",
  outlet_list: "outlet.list",
  edit_outlet: "edit.outlet",
  export_outlet: "export.outlet",
  seminar: "seminar",
  seminar_list: "seminar.list",
  create_seminar: "create.seminar",
  edit_seminar: "edit.seminar",
  delete_seminar: "delete.seminar",
  export_seminar: "export.seminar",
  seminar_booking: "seminar.booking",
  seminar_booking_list: "seminar.booking.list",
  seminar_booking_edit: "seminar.booking.edit",
  seminar_booking_delete: "seminar.booking.delete",
  seminar_booking_export: "seminar.booking.export",
  notification: "notification",
  manual_notification: "manual.notification",
  list_manual_notification: "list.manual.notification",
  create_manual_notification: "create.manual.notification",
  edit_manual_notification: "edit.manual.notification",
  delete_manual_notification: "delete.manual.notification",
  setting: "setting",
  maintenance_setting: "maintenance.setting",
  edit_maintenance_setting: "edit.maintenance.setting",
  whatsapp_notification_setting: "whatsapp.notification.setting",
  edit_whatsapp_notification_setting: "edit.whatsapp.notification.setting",
  auto_notification_setting: "auto.notification.setting",
  edit_auto_notification_setting: "edit.auto.notification.setting",
  contact_setting: "contact.setting",
  edit_contact_setting: "edit.contact.setting",
  other: "other",

  app_banner:"app.banner",
  app_banner_list:"app.banner.list",
  edit_app_banner:"edit.app.banner",
  
  lock_user: "lock.user",
  lock_user_list: "lock.user.list",
  lock_user_unlock: "lock.user.unlock",
  lock_user_export: "lock.user.export",
  fault_type: "fault.type",
  fault_type_list: "fault.type.list",
  create_fault_type: "create.fault.type",
  edit_fault_type: "edit.fault.type",
  delete_fault_type: "delete.fault.type",
  export_fault_type: "export.fault.type",
  reward: "reward",
  scan_reward: "scan.reward",
  scan_reward_list: "scan.reward.list",
  create_scan_reward: "scan.reward.create",
  edit_scan_reward: "scan.reward.edit",
  export_scan_reward: "scan.reward.export",
  create_ticket: "ticket.create",
  ticket_list: "ticket.list",
  ticket_export: "export.ticket",
  assign_ticket:"assign.ticket",

  marquee_type: "marquee.report",
  marquee_type_list: "marquee.report.list",
  create_marquee_type: "create.marquee.report",
  edit_marquee_type: "edit.marquee.report",
  delete_marquee_type: "delete.fault.type",
  export_marquee_type: "export.marquee.report",

  whats_new:"whats.new",
  whats_new_report: "whats.new.report",
  whats_new_report_list: "whats.new.report.list",
  create_whats_new_Report: "create.whats.new.report",
  edit_whats_new_Report: "edit.whats.new.report",
  delete_whats_new_Report: "delete.whats.new.report",
  export_whats_new_Report: "export.whats.new.report",
  whats_new_order:"whats.new.order",
  manage_whats_new_order:"manage.whats.new.order"
};
// export const checkUserPermission = (functionalityName: string) => {
//   const permissionList = getUserPermissionList();
//   console.log(permissionList);

//   switch (functionalityName) {
//     case 'company_list':
//       return permissionList.includes('company.list');
//     case 'create_company':
//       return permissionList.includes('create.company');
//     case 'edit_company':
//       return permissionList.includes('edit.company');
//     case 'other':
//       return true;
//     default:
//       return false;
//   }
// };
export const hasUserAccessFunctionality = (functionalityName) => {
  const hasPermission = checkUserPermissionList(functionalityName);
  return hasPermission;
};
export const checkUserPermissionList = (functionalityList) => {
  const permissionList = getUserPermissionList();
  permissionList.push("other");
  const hasPermission = permissionList.some((path) =>
    functionalityList.includes(path)
  );
  return hasPermission;
};
