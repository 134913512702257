import React, { lazy } from "react";

// signin pages
const LoginPage = lazy(() => import("pages/Login"));
const ForgotPasswordPage = lazy(() => import("pages/Login/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("pages/Login/ResetPassword"));
// admin routes
const DashboardPage = lazy(() => import("pages/Dashboard"));
//outlate
const OutlatePage = lazy(() => import("pages/Outlate"));
// staff
const StaffManagementPage = lazy(() => import("pages/StaffManagement"));
//.... role
const RolePage = lazy(() => import("pages/StaffManagement/Role"));
const AddRolePage = lazy(() => import("pages/StaffManagement/Role/AddRole"));
const EditRolePage = lazy(() => import("pages/StaffManagement/Role/EditRole"));
// staff
const StaffPage = lazy(() => import("pages/StaffManagement/Staff"));
const AddStaffPage = lazy(() => import("pages/StaffManagement/Staff/AddStaff"));
const EditStaffPage = lazy(() =>
  import("pages/StaffManagement/Staff/EditStaff")
);
//Seminar
const SeminarPage = lazy(() => import("pages/Seminar"));
const AddSeminar = lazy(() => import("pages/Seminar/AddSeminar"));
const EditSeminar = lazy(() => import("pages/Seminar/EditSeminar"));
const SeminarBookingPage = lazy(() => import("pages/Seminar/SeminarBooking"));
// const EditSeminar = lazy(() => import("pages/Seminar/EditSeminar"));
// dealers
const DealersPage = lazy(() => import("pages/Dealers"));
const DealerReportPage = lazy(() => import("pages/Dealers/DealersReport"));

const DealerPointSummaryPage = lazy(() =>
  import("pages/Dealers/DealersReport/DealerPointSummary")
);
const DealerRewardSummaryPage = lazy(() =>
  import("pages/Dealers/DealersReport/DealerRewardSummary")
);
const VerifyDealerAccountPage = lazy(() =>
  import("pages/Dealers/VerifyDealerAccount")
);
// sub dealers
const SubDealersPage = lazy(() => import("pages/SubDealers"));
const SubDealerReportPage = lazy(() =>
  import("pages/SubDealers/SubDealersReport")
);
const SubDealerPointSummaryPage = lazy(() =>
  import("pages/SubDealers/SubDealersReport/SubDealerPointSummary")
);
const SubDealerRewardSummaryPage = lazy(() =>
  import("pages/SubDealers/SubDealersReport/SubDealerRewardSummary")
);
const VerifySubDealerAccountPage = lazy(() =>
  import("pages/SubDealers/VerifySubDealerAccount")
);
//technicians
const TechniciansPage = lazy(() => import("pages/Technicians"));
const TechniciansReportPage = lazy(() =>
  import("pages/Technicians/TechniciansReport")
);
const TechniciansPointSummaryPage = lazy(() =>
  import("pages/Technicians/TechniciansReport/TechnicianPointSummary")
);
const TechniciansRewardSummaryPage = lazy(() =>
  import("pages/Technicians/TechniciansReport/TechnicianRewardSummary")
);
//othermodule
const OtherModulePage = lazy(() => import("pages/OtherModule"));
const LockUserPage = lazy(() => import("pages/OtherModule/LockUser"));
const FultReportPage = lazy(() => import("pages/OtherModule/FultReport"));
const MarqueeReportPage = lazy(() => import("pages/OtherModule/MarqueeReport"));
// const NewReportPage = lazy(() => import("pages/WhatsNewReport/NewReport"));

//banner
const BannerModulePage = lazy(() => import("pages/BannerModule"));
const FirstBanner = lazy(() => import("pages/BannerModule/FirstBanner"));
const SecondBanner = lazy(() => import("pages/BannerModule/SecondBanner"));
const ThirdBanner = lazy(() => import("pages/BannerModule/ThirdBanner"));
const ForthBanner = lazy(() => import("pages/BannerModule/ForthBanner"));

//whats new report
const WhatsNewReportModulePage = lazy(() => import("pages/BannerModule"));
const NewReportPage = lazy(() => import("pages/WhatsNewReport/NewReport"));
const ListNewReportOrder = lazy(() =>
  import("pages/WhatsNewReport/NewReportOrder")
);

//reward module
const RewardModulePage = lazy(() => import("pages/RewardModule"));
const ScanRewardPage = lazy(() => import("pages/RewardModule/ScanReward"));
const AddScanRewardPage = lazy(() =>
  import("pages/RewardModule/ScanReward/AddScanReward")
);
const EditScanRewardPage = lazy(() =>
  import("pages/RewardModule/ScanReward/EditScanReward")
);
//bonus point module
const BonusPointModulePage = lazy(() => import("pages/BonusPointModule"));
const BonusPointAchieverListPage = lazy(() =>
  import("pages/BonusPointModule/BonusPointAchiever")
);
//profile
const UpdateProfile = lazy(() => import("pages/Profile/UpdateProfile"));
const ReportPage = lazy(() => import("pages/Reports"));
//otp report
const OtpReportPage = lazy(() => import("pages/Reports/OtpReport"));
// scan code report
const ScanCodeReportPage = lazy(() => import("pages/Reports/ScanCodeReport"));
// overall reward achieved
const OverallRewardAchievedReportPage = lazy(() =>
  import("pages/Reports/OverallRewardAchievedReport")
);
// overall point achieved
const OverallPointAchievedReportPage = lazy(() =>
  import("pages/Reports/OverallPointAchievedReport")
);
// overall Reward Redeem
const OverallRewardRedeemReportPage = lazy(() =>
  import("pages/Reports/OverallRewardRedeemReport")
);
// check user detail form
const CheckUserDetailPage = lazy(() =>
  import("pages/Reports/UserDetailReport")
);
// monthwise scan report
const MonthwiseScanPercentageReportPage = lazy(() =>
  import("pages/Reports/MonthwiseScanPercentageReport")
);
const WhatsappMassageReportPage = lazy(() =>
  import("pages/Reports/WhatsappMessageReport")
);
//category master
const CategoryMasterPage = lazy(() => import("pages/CategoryMaster"));
const CategoryReportPage = lazy(() =>
  import("pages/CategoryMaster/CategoryReport")
);
const AchievementOrderPage = lazy(() =>
  import("pages/CategoryMaster/AchievementOrder")
);
//catagory
const CatalogPage = lazy(() => import("pages/Catalog"));
const CatalogReportPage = lazy(() => import("pages/Catalog/CatalogReport"));
const CatalogOrderPage = lazy(() => import("pages/Catalog/CatalogOrder"));
//notification
const NotificationPage = lazy(() => import("pages/Notification"));
//manual notification
const ManualNotificationPage = lazy(() =>
  import("pages/Notification/ManualNotification")
);
const TicketDetailPage = lazy(() => import("pages/Tickets"));
const AddManualNotification = lazy(() =>
  import("pages/Notification/ManualNotification/AddManualNotification")
);
const EditManualNotification = lazy(() =>
  import("pages/Notification/ManualNotification/EditManualNotification")
);
// const EditManualNotification = lazy(() =>
//   import("pages/Notification/ManualNotification/EditManualNotification")
// );
//setting
const SettingPage = lazy(() => import("pages/SettingsModule"));
const MaintenanceSettingPage = lazy(() =>
  import("pages/SettingsModule/MaintenanceSetting")
);
const WhatsappNotificationSettingPage = lazy(() =>
  import("pages/SettingsModule/WhatsappNotificationSetting")
);
const AutoNotificationSettingPage = lazy(() =>
  import("pages/SettingsModule/AutoNotificationSettings")
);
const SocialMediaSettingPage = lazy(() =>
  import("pages/SettingsModule/SocialMediaSetting")
);
export const techniciansRoutes = {
  techniciansReportList: {
    path: "technicians-report",
    element: <TechniciansReportPage />,
  },
  techniciansPointSummaryList: {
    path: "technicians-report/technician-point-summary/:id",
    element: <TechniciansPointSummaryPage />,
  },
  techniciansRewardSummaryList: {
    path: "technicians-report/technician-reward-summary/:id",
    element: <TechniciansRewardSummaryPage />,
  },
};
export const otherModulRoutes = {
  lockUserList: {
    path: "lock-user",
    element: <LockUserPage />,
  },
  fultReportList: {
    path: "fult-report",
    element: <FultReportPage />,
  },
  marqueeReportList: {
    path: "marquee-report",
    element: <MarqueeReportPage />,
  },
  // newReportList: {
  //   path: "whats-new-report",
  //   element: <NewReportPage />,
  // },
};
export const bannnerModulRoutes = {
  firstBanner: {
    path: "first-banner",
    element: <FirstBanner />,
  },
  secondBanner: {
    path: "second-banner",
    element: <SecondBanner />,
  },
  thirdBanner: {
    path: "third-banner",
    element: <ThirdBanner />,
  },
  forthBanner: {
    path: "forth-banner",
    element: <ForthBanner />,
  },
};
export const whatsNewReportModulRoutes = {
  newReportList: {
    path: "whats-new-report",
    element: <NewReportPage />,
  },
  newReportOrder: {
    path: "new-report-order",
    element: <ListNewReportOrder />,
  },
};
export const rewardModulRoutes = {
  scanReward: {
    path: "scan-reward",
    element: <ScanRewardPage />,
  },
  addScanReward: {
    path: "add-scan-reward",
    element: <AddScanRewardPage />,
  },
  updateScanReward: {
    path: "edit-scan-reward/:id",
    element: <EditScanRewardPage />,
  },
};
export const bonusPointModulRoutes = {
  bonusPointAchieverList: {
    path: "bonus-point-achiever",
    element: <BonusPointAchieverListPage />,
  },
};
export const notificationRoutes = {
  manualNotification: {
    path: "manual-notification",
    element: <ManualNotificationPage />,
  },
  addMenualNotification: {
    path: "add-menual-notification",
    element: <AddManualNotification />,
  },
  updateMenualNotification: {
    path: "edit-menual-notification/:id",
    element: <EditManualNotification />,
  },
};
export const settingRoutes = {
  maintenanceSettingList: {
    path: "maintenance-setting",
    element: <MaintenanceSettingPage />,
  },
  whatsappNotificationSettingList: {
    path: "whatsapp-notification",
    element: <WhatsappNotificationSettingPage />,
  },
  autoNotificationSettingList: {
    path: "auto-notification",
    element: <AutoNotificationSettingPage />,
  },
  socialMediaSettingPageList: {
    path: "social-media",
    element: <SocialMediaSettingPage />,
  },
};
export const reportRoutes = {
  otpReportList: {
    path: "otp-report",
    element: <OtpReportPage />,
  },
  scanCodeReportList: {
    path: "scan-code-report",
    element: <ScanCodeReportPage />,
  },
  whatsappMessageReportList: {
    path: "whatsapp-massage-report",
    element: <WhatsappMassageReportPage />,
  },
  checkUserDetail: {
    path: "check-user-detail",
    element: <CheckUserDetailPage />,
  },
  monthwiseScanPercentageReport: {
    path: "monthwise-scan-percentage-report",
    element: <MonthwiseScanPercentageReportPage />,
  },
  overallRewardAchievedList: {
    path: "overall-reward-achieved",
    element: <OverallRewardAchievedReportPage />,
  },
  overallPointAchievedList: {
    path: "overall-point-achieved",
    element: <OverallPointAchievedReportPage />,
  },
  overallRewardRedeemList: {
    path: "overall-reward-redeem",
    element: <OverallRewardRedeemReportPage />,
  },
};
export const categoryMasterRoutes = {
  categoryReportList: {
    path: "category-report",
    element: <CategoryReportPage />,
  },
  achievementOrderList: {
    path: "achievement-order",
    element: <AchievementOrderPage />,
  },
};
export const catalogRoutes = {
  catalogReportList: {
    path: "catalog-report",
    element: <CatalogReportPage />,
  },
  catalogOrderList: {
    path: "catalog-order",
    element: <CatalogOrderPage />,
  },
};

export const subDealerRoutes = {
  subDealerReportList: {
    path: "sub-dealer-report",
    element: <SubDealerReportPage />,
  },
  VerifySubDealerAccountList: {
    path: "sub-dealer-account",
    element: <VerifySubDealerAccountPage />,
  },
  subDealerPointSummaryList: {
    path: "sub-dealer-report/sub-dealer-point-summary/:id",
    element: <SubDealerPointSummaryPage />,
  },
  subDealerRewardSummaryList: {
    path: "sub-dealer-report/sub-dealer-reward-summary/:id",
    element: <SubDealerRewardSummaryPage />,
  },
};
export const dealerRoutes = {
  dealerReportList: { path: "dealer-report", element: <DealerReportPage /> },
  VerifyDealerAccountList: {
    path: "verify-dealer-account",
    element: <VerifyDealerAccountPage />,
  },
  dealerPointSummaryList: {
    path: "dealer-report/dealer-point-summary/:id",
    element: <DealerPointSummaryPage />,
  },
  dealerRewardSummaryList: {
    path: "dealer-report/dealer-reward-summary/:id",
    element: <DealerRewardSummaryPage />,
  },
};
export const staffRoutes = {
  roleList: { path: "role", element: <RolePage /> },
  addRole: { path: "add-role", element: <AddRolePage /> },
  updateRole: { path: "edit-role/:id", element: <EditRolePage /> },
  staffList: { path: "staff-list", element: <StaffPage /> },
  addStaff: { path: "add-staff", element: <AddStaffPage /> },
  updateStaff: { path: "edit-staff/:id", element: <EditStaffPage /> },
};
export const mainRoutes = {
  dashboard: { path: "/", element: <DashboardPage /> },
  // role
  // role: { path: "/role", element: <RolePage /> },
  staff: {
    path: "/staff",
    element: <StaffManagementPage />,
    routes: staffRoutes,
  },
  //
  outlate: { path: "/outlate", element: <OutlatePage /> },

  seminar: { path: "/seminar", element: <SeminarPage /> },
  addSeminar: { path: "/add-seminar", element: <AddSeminar /> },
  updateSeminar: { path: "/edit-seminar/:id", element: <EditSeminar /> },

  seminarBokking: {
    path: "/seminar-booking/:id",
    element: <SeminarBookingPage />,
  },
  // editSeminar: { path: "/edit-seminar", element: <EditSeminar /> },
  updateprofile: { path: "/update-profile", element: <UpdateProfile /> },
  dealer: {
    path: "/dealer",
    element: <DealersPage />,
    routes: dealerRoutes,
  },
  subDealer: {
    path: "/sub-dealer",
    element: <SubDealersPage />,
    routes: subDealerRoutes,
  },
  technicians: {
    path: "/technicians",
    element: <TechniciansPage />,
    routes: techniciansRoutes,
  },
  otherModule: {
    path: "/other",
    element: <OtherModulePage />,
    routes: otherModulRoutes,
  },
  bannerModule: {
    path: "/banner",
    element: <BannerModulePage />,
    routes: bannnerModulRoutes,
  },
  WhatsNewReportModule: {
    path: "/whats-new-report",
    element: <WhatsNewReportModulePage />,
    routes: whatsNewReportModulRoutes,
  },
  rewardModule: {
    path: "/reward",
    element: <RewardModulePage />,
    routes: rewardModulRoutes,
  },
  bonusPointModule: {
    path: "/bonus-point",
    element: <BonusPointModulePage />,
    routes: bonusPointModulRoutes,
  },
  reports: {
    path: "/reports",
    element: <ReportPage />,
    routes: reportRoutes,
  },
  CategoryMaster: {
    path: "/category-master",
    element: <CategoryMasterPage />,
    routes: categoryMasterRoutes,
  },
  Catalog: {
    path: "/catalog",
    element: <CatalogPage />,
    routes: catalogRoutes,
  },
  Setting: {
    path: "/setting",
    element: <SettingPage />,
    routes: settingRoutes,
  },
  Notification: {
    path: "/notification",
    element: <NotificationPage />,
    routes: notificationRoutes,
  },
  ticketDetail: { path: "/ticket-detail/:id", element: <TicketDetailPage /> },
};

export const loginRoutes = {
  login: { path: "/login", element: <LoginPage /> },
  forgotPassword: { path: "/forgot-password", element: <ForgotPasswordPage /> },
  resetPassword: { path: "/reset-password", element: <ResetPasswordPage /> },
};
